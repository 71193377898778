import classNames from "classnames"
import { useRef, useState } from "react"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import { useOutsideAlerter } from "../../../utils/outsideClickAlerter"
import CheckBox from "../../atoms/CheckBox/CheckBox"
import { Radio } from "../../atoms/Radio"
import { DateRangeData } from "../DatePicker/helpers"
import "./Filter.css"

export enum FilterType {
  DATE = "date",
  DATEYEAR = "dateyear",
  FORMAT = "format",
  STATUS = "status",
  CONTACT = "contact",
  ACTION = "action",
  NAME = "name",
  VISIBILITY = "visibility",
}

export interface TFilterOption {
  name: string
  checked: boolean
  value: string
  type: string
  disabled?: boolean
  numberOfResults?: number
  /**
   * used for date range
   * stores start and end date value
   */
  dateRange?: DateRangeData
  id?: string
}

const getLabelWithCount = (
  showSelectedCount: boolean,
  checkedOptions: TFilterOption[]
) => {
  return showSelectedCount && checkedOptions[0].numberOfResults
    ? checkedOptions[0].name + " (" + checkedOptions[0].numberOfResults + ")"
    : checkedOptions[0].name
}

const getSelectedValue = ({
  options,
  allName,
  noneName,
  canSelectMultiple,
  showSelectedCount,
  hasAllOption,
  alternativeAllName,
}: {
  options: TFilterOption[]
  allName: string
  noneName: string
  canSelectMultiple: boolean
  showSelectedCount: boolean
  hasAllOption?: boolean
  alternativeAllName?: string
}) => {
  const checkedOptions = options.filter((o) => o.checked && !o.disabled)
  const availableOptions = options.filter((o) => !o.disabled)

  const allOptionsChecked = checkedOptions.length === availableOptions.length

  if (
    allOptionsChecked ||
    (!canSelectMultiple && checkedOptions.length === 0)
  ) {
    if (hasAllOption) {
      if (alternativeAllName) {
        return alternativeAllName
      }
      return `All ${allName}`
    } else if (availableOptions.length === 1) {
      return getLabelWithCount(showSelectedCount, checkedOptions)
    } else {
      return allName
    }
  } else if (checkedOptions.length === 1) {
    return getLabelWithCount(showSelectedCount, checkedOptions)
  } else if (canSelectMultiple && checkedOptions.length === 0) {
    return noneName ? noneName : "No " + allName + " selected"
  }
  return `(${checkedOptions.length}) Multiple`
}

export interface FilterProps {
  className?: string
  options: TFilterOption[]
  isDisabled?: boolean
  label?: string
  setOptions: (options: TFilterOption[]) => void
  canSelectMultiple?: boolean
  hasAllOption?: boolean
  allName?: string
  allNumberOfResults?: number
  noneName?: string
  isHorribleLegacyCode?: boolean
  showSelectedCount?: boolean
  // Below are new props to refactor gradually this Filter component
  onRadioOptionSelected?: (radioOption: TFilterOption) => void
  onAllRadioOptionSelected?: () => void
  alternativeAllName?: string
}

export const Filter: React.FC<FilterProps> = ({
  className,
  options,
  isDisabled = false,
  label,
  setOptions,
  canSelectMultiple = true,
  hasAllOption = true,
  allName = "",
  allNumberOfResults,
  noneName = "",
  isHorribleLegacyCode = true,
  showSelectedCount = false,
  onRadioOptionSelected,
  onAllRadioOptionSelected,
  alternativeAllName,
}) => {
  const [open, setOpen] = useState(false)

  const IconChevronUp = useGetIcon("IconChevronUp")
  const IconChevronDown = useGetIcon("IconChevronDown")

  const getDataId = () => {
    if (label) {
      return label?.toLowerCase().trim().replace(/ /g, "-")
    } else if (hasAllOption) {
      return allName?.toLowerCase().trim().replace(/ /g, "-")
    }
    return "no-label"
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, setOpen)

  const allOptionsChecked = (options: TFilterOption[]) => {
    return options.every((option) => option.checked)
  }

  const filterWrapperClass = classNames("filter-wrapper", className, {
    "content-on": open,
    "content-off": !open,
  })

  const formSelectClass = classNames("form-select", className)

  const fieldsetClass = classNames("select-content", {
    "content-off": !open,
  })

  const formSelectInputClass = classNames("input", {
    disabled: isDisabled,
  })

  const AllFilterOption = () => {
    const allLabel = alternativeAllName ? alternativeAllName : `All ${allName}`

    const handleAllOptionRadioOnClick = () => {
      if (onAllRadioOptionSelected) {
        onAllRadioOptionSelected()
      } else {
        setOptions(
          options.map((option) => ({
            ...option,
            checked: isHorribleLegacyCode ? true : false,
          }))
        )
        setOpen(false)
      }
    }

    return (
      <label key={allLabel} id={`${getDataId()}-All`}>
        {canSelectMultiple && (
          <CheckBox
            onChange={(event) => {
              setOptions(
                options.map((option) => ({
                  ...option,
                  checked: event!.target.checked,
                }))
              )
            }}
            id={allLabel}
            isChecked={options.every((option) => option.checked)}
          />
        )}
        {!canSelectMultiple && (
          <Radio
            isChecked={
              allOptionsChecked(options) || !allOptionsChecked(options)
            }
            name="Filter"
            value={allLabel}
            onClick={() => {
              handleAllOptionRadioOnClick()
            }}
            ariaLabelledBy={`${getDataId()}-All`}
          />
        )}
        {allLabel}
        {allNumberOfResults !== undefined && (
          <span>({allNumberOfResults})</span>
        )}
      </label>
    )
  }

  const FilterOptionsList = () => {
    const replaceSpacesWithDashes = (text: string) => {
      return text ? text.replaceAll(" ", "-") : ""
    }

    const handleSelectedRadioOptionChange = (option: TFilterOption) => {
      if (onRadioOptionSelected) {
        onRadioOptionSelected(option)
      } else {
        setOptions(
          options.map((filterOption) => ({
            ...filterOption,
            checked: filterOption.value === option.value,
          }))
        )
        setOpen(false)
      }
    }

    return (
      <>
        {options.map((option, index) => (
          <label
            className={`${
              (option.disabled || option.numberOfResults === 0) && "disabled"
            }`}
            key={`${index} ${option.name}`}
            id={replaceSpacesWithDashes(option.name)}
            htmlFor={index + "-" + option.name}
          >
            {canSelectMultiple && (
              <CheckBox
                id={index + "-" + option.name}
                onChange={() =>
                  setOptions(
                    options.map((filterOption) => ({
                      ...filterOption,
                      checked:
                        filterOption.name === option.name
                          ? !option.checked
                          : filterOption.checked,
                    }))
                  )
                }
                isChecked={option.checked}
                isDisabled={option.disabled ?? false}
              />
            )}
            {!canSelectMultiple && (
              <Radio
                isChecked={
                  option.checked &&
                  (!allOptionsChecked(options) || options.length <= 1)
                }
                name="Filter"
                value={option.value}
                isDisabled={option.disabled ?? false}
                onClick={() => {
                  handleSelectedRadioOptionChange(option)
                }}
                ariaLabelledBy={replaceSpacesWithDashes(option.name)}
                id={index + "-" + option.name}
              />
            )}
            <span data-testid={`filter-option-${index}`}>{option.name}</span>
            {option.numberOfResults !== undefined && (
              <span>({option.numberOfResults})</span>
            )}
          </label>
        ))}
      </>
    )
  }

  return (
    <>
      <div data-testid="filter" className={filterWrapperClass}>
        {label && (
          <label
            className="select-label"
            id={getDataId()}
            aria-label={`filter for ${allName ?? label}`}
            htmlFor={`filter-select-${getDataId()}`}
          >
            {label}
          </label>
        )}

        <div
          className={formSelectClass}
          ref={wrapperRef}
          data-testid={`filter-select-${getDataId()}`}
        >
          <div className="form-select-wrapper">
            <input
              className={formSelectInputClass}
              type="text"
              readOnly={true}
              value={getSelectedValue({
                options,
                allName,
                noneName,
                canSelectMultiple,
                showSelectedCount,
                hasAllOption,
                alternativeAllName,
              })}
              placeholder={allName}
              onClick={(e) => {
                e.preventDefault()
                setOpen(!open)
              }}
              disabled={isDisabled}
              role="combobox"
              aria-expanded={open}
              aria-haspopup="listbox"
              aria-controls="select-filter-options"
              aria-labelledby={getDataId()}
              aria-label={getDataId()}
              id={`filter-select-${getDataId()}`}
            />
            {open && (
              <fieldset className={fieldsetClass}>
                {hasAllOption && <AllFilterOption />}
                <FilterOptionsList />
              </fieldset>
            )}
            <span className="open-close-svg" onClick={() => setOpen(!open)}>
              {open ? IconChevronUp : IconChevronDown}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
