"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
__exportStar(require("./apis/activity-api"), exports);
__exportStar(require("./apis/adviser-api"), exports);
__exportStar(require("./apis/auth-api"), exports);
__exportStar(require("./apis/bulk-distribution-api"), exports);
__exportStar(require("./apis/connectivity-api"), exports);
__exportStar(require("./apis/document-library-api"), exports);
__exportStar(require("./apis/docusign-api"), exports);
__exportStar(require("./apis/feature-api"), exports);
__exportStar(require("./apis/health-api"), exports);
__exportStar(require("./apis/node-api"), exports);
__exportStar(require("./apis/notification-api"), exports);
__exportStar(require("./apis/organisation-api"), exports);
__exportStar(require("./apis/pub-sub-api"), exports);
__exportStar(require("./apis/reminder-api"), exports);
__exportStar(require("./apis/sharing-api"), exports);
__exportStar(require("./apis/user-api"), exports);
__exportStar(require("./apis/workflow-api"), exports);
__exportStar(require("./custom/upload-api"), exports);
