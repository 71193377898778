import { FC, ReactNode, Suspense, lazy, useEffect } from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { IUser } from "../api/api-client/api-types"
import { MainLayoutWrapper } from "../component/layouts/MainLayoutWrapper"
import { LoadingFallBack } from "../component/organism/LoadingFallBack"
import {
  setAuthLoading,
  setSelectedTheme,
  setSelectedUserSharingWithMe,
} from "../contexts/application/action"
import { PAGE_PATH, UserModuleType } from "../contexts/application/constants"
import { useApplicationContext } from "../contexts/application/context"
import { usePlatformContext } from "../contexts/platform/platformContextProvider"
import { useThumbnailContext } from "../contexts/thumbnails"
import {
  setDisplaySuccessOrErrorMessage,
  useToastContext,
} from "../contexts/toasts"
import { useUserContext } from "../contexts/users"
import { loadUserData } from "../contexts/users/loaduserData"
import { notificationMessages } from "../data/notificationMessages"
import { TThemeName } from "../design-tokens/types"
import { useRefreshTokenOnTabFocus } from "../hooks/useRefreshTokenOnTabFocus"
import { useScrollToTopOnChange } from "../hooks/useScrollToTopOnChange"
import { DocusignRedirectPage } from "../pages/DocusignRedirectPage/DocusignRedirectPage"
import ErrorPage from "../pages/ErrorPage/ErrorPage"
import LoginPage from "../pages/LoginPage/LoginPage"
import { LogoutCaseManagerPage } from "../pages/LogoutPage/LogoutCaseManagerPage"
import { LogoutPage } from "../pages/LogoutPage/LogoutPage"
import RegisterPage from "../pages/RegisterPage/RegisterPage"
import ResetPasswordPage from "../pages/ResetPasswordPage/ResetPasswordPage"
import { apiConnect } from "../utils/auth/login"
import { loginTypes } from "../utils/auth/loginTypes"
import { ProtectedRoute } from "./ProtectedRoute"
import { Redirect } from "./Redirect"
import RequireAuth from "./RequireAuth"

const CoopUnauthorisedPage = lazy(
  () => import("../pages/UnauthorisedPages/Coop/CoopUnauthorisedPage")
)

const HomePage = lazy(() => import("../pages/Homepage/HomePage"))
const ThemePage = lazy(() => import("../pages/ThemePage/ThemePage"))
const SharingPage = lazy(() => import("../pages/Sharing/SharingPage"))
const SearchResultPage = lazy(
  () => import("../pages/SearchResultPage/SearchResultPage")
)
const FolderPage = lazy(() => import("../pages/FolderPage/FolderPage"))
const SharedWithMePage = lazy(
  () => import("../pages/SharedWithMePage/SharedWithMePage")
)
const NotificationsPage = lazy(
  () => import("../pages/NotificationsPage/NotificationsPage")
)

const NotificationsPageCoop = lazy(
  () => import("../pages/NotificationsPage/NotificationsPageCoop")
)
const RecentActivityPage = lazy(
  () => import("../pages/RecentActivityPage/RecentActivityPage")
)
const ConnectivityPage = lazy(
  () => import("../pages/ConnectivityPage/ConnectivityPage")
)
const PrimerPage = lazy(() => import("../pages/PrimerPage/PrimerPage"))
const ProfilePage = lazy(() => import("../pages/ProfilePage/ProfilePage"))
const DocusignRequestPage = lazy(
  () => import("../pages/DocusignRequestPage/DocusignRequestPage")
)
const DocusignRequestIframePage = lazy(
  () => import("../pages/DocusignRequestIframePage/DocusignRequestIframePage")
)
const DocusignRequestSentPage = lazy(
  () => import("../pages/DocusignRequestSentPage/DocusignRequestSentPage")
)
const ProfilePageCoop = lazy(
  () => import("../pages/ProfilePage/ProfilePageCoop")
)
const ClientsPage = lazy(() => import("../pages/ClientsPage/ClientsPage"))

//Code Splitting for Communications
const AccountDocumentLibraryPage = lazy(
  () =>
    import(
      "../pages/Communications/AccountDocumentLibraryPage/AccountDocumentLibraryPage"
    )
)
const DocumentLibraryPage = lazy(
  () =>
    import("../pages/Communications/DocumentLibraryPage/DocumentLibraryPage")
)
const DocumentHubPage = lazy(
  () => import("../pages/Communications/DocumentHubPage/DocumentHubPage")
)

const CommunicationsSearchPage = lazy(
  () =>
    import(
      "../pages/Communications/CommunicationsSearchPage/CommunicationsSearchPage"
    )
)
const AnalyticsPage = lazy(
  () => import("../pages/Communications/AnalyticsPage/AnalyticsPage")
)

//Code Splitting for Organisations
const OrganisationsPage = lazy(
  () => import("../pages/Organisations/OrganisationsPage/OrganisationsPage")
)
const ClientOrganisationsPage = lazy(
  () =>
    import("../pages/Organisations/OrganisationClients/OrganisationClientsPage")
)

//Code Splitting for Probate Case Pages
const CaseDocumentsPage = lazy(
  () => import("../pages/ProbateCasePages/CaseDocumentsPage/CaseDocumentsPage")
)
const CaseDocumentDetailsPage = lazy(
  () =>
    import(
      "../pages/ProbateCasePages/CaseDocumentDetailsPage/CaseDocumentDetailsPage"
    )
)
const CaseSharingPage = lazy(
  () => import("../pages/ProbateCasePages/CaseSharingPage/CaseSharingPage")
)
const CaseSharedWithMePage = lazy(
  () =>
    import(
      "../pages/ProbateCasePages/CaseSharedWithMePage/CaseSharedWithMePage"
    )
)
const CaseSharedWithMeFileDetailsPage = lazy(
  () =>
    import(
      "../pages/ProbateCasePages/CaseSharedWithMeFileDetailsPage/CaseSharedWithMeFileDetailsPage"
    )
)

const CaseSearchPage = lazy(
  () => import("../pages/ProbateCasePages/CaseSearchPage/CaseSearchPage")
)

const CaseManagerRequestPage = lazy(
  () =>
    import(
      "../pages/ProbateCasePages/CaseManagerRequestPage/CaseManagerRequestPage"
    )
)
const CaseManagerHomePage = lazy(
  () =>
    import("../pages/ProbateCasePages/CaseManagerHomePage/CaseManagerHomePage")
)
const CaseManagerAnalyticsPage = lazy(
  () =>
    import(
      "../pages/ProbateCasePages/CaseManagerAnalyticsPage/CaseManagerAnalyticsPage"
    )
)
const ChooseProbateCasePage = lazy(
  () =>
    import(
      "../pages/ProbateCasePages/ChooseProbateCasePage/ChooseProbateCasePage"
    )
)
const CaseDetailsPage = lazy(
  () => import("../pages/ProbateCasePages/CaseDetailsPage/CaseDetailsPage")
)
const AddSignaturePage = lazy(
  () => import("../pages/ProbateCasePages/AddSignaturePage/AddSignaturePage")
)
const CaseManagerDocumentSentPage = lazy(
  () =>
    import(
      "../pages/ProbateCasePages/CaseManagerDocumentSentPage/CaseManagerDocumentSentPage"
    )
)
const CaseManagerDocumentTaggerIframePage = lazy(
  () =>
    import(
      "../pages/ProbateCasePages/CaseManagerDocumentTaggerIframePage/CaseManagerDocumentTaggerIframePage"
    )
)

const CaseManagerSSOPage = lazy(
  () =>
    import("../pages/ProbateCasePages/CaseManagerSSOPage/CaseManagerSSOPage")
)

export const CommunicationsModules = [
  UserModuleType.DOCUMENT_HUB,
  UserModuleType.DOCUMENTS_LIBRARY_ADVISER_VIEW,
  UserModuleType.DOCUMENT_LIBRARY,
]

export const AllRoutes = () => {
  const {
    applicationState: { usersSharingWithMe, authLoading },
    dispatch,
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()
  const { dispatch: toastDispatch } = useToastContext()

  const { loginType } = usePlatformContext()

  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  useScrollToTopOnChange(pathname)

  const searchParams = Object.fromEntries(new URLSearchParams(search).entries())

  useEffect(() => {
    if (
      !currentUser ||
      !search ||
      !usersSharingWithMe ||
      usersSharingWithMe.length === 0
    )
      return

    if (searchParams.client) {
      const selectedUser = usersSharingWithMe.find(
        (user: IUser) => user.email === searchParams.client
      )

      if (selectedUser) {
        dispatch(setSelectedUserSharingWithMe(selectedUser.userId))
      } else {
        toastDispatch(
          setDisplaySuccessOrErrorMessage({
            messageType: "ERROR",
            message: notificationMessages.invalidUser.ERROR,
          })
        )
      }
      navigate(PAGE_PATH.SharedWithMePage)
    }
  }, [
    currentUser,
    dispatch,
    navigate,
    search,
    searchParams,
    toastDispatch,
    usersSharingWithMe,
  ])

  function hasModules(m: UserModuleType[]): boolean {
    let res = false
    m.forEach((module) => {
      if (currentUser?.modules?.includes(module)) res = true
    })
    return res
  }

  const showLoginPage =
    loginType === loginTypes.PASSWORD ||
    loginType === loginTypes.PASSWORDLESS ||
    loginType === loginTypes.MIXED

  if (!currentUser || (currentUser && showLoginPage)) {
    return (
      <Routes>
        {showLoginPage && (
          <>
            <Route path={PAGE_PATH.LoginPage} element={<LoginPage />} />
            <Route path={PAGE_PATH.RegisterPage} element={<RegisterPage />} />
            <Route
              path={PAGE_PATH.ResetPasswordPage}
              element={<ResetPasswordPage />}
            />
            <Route
              path={PAGE_PATH.CaseManagerSSOPage}
              element={<CaseManagerSSOPage />}
            />
          </>
        )}

        {/* Case tracker pages */}
        <Route element={<ProtectedRoute />}>
          <Route path={PAGE_PATH.Cases} element={<ChooseProbateCasePage />} />
          <Route
            path={PAGE_PATH.CaseDetailsPage}
            element={<CaseDetailsPage />}
          />
          <Route
            path={PAGE_PATH.CaseDocuments}
            element={<CaseDocumentsPage />}
          />
          <Route
            path={PAGE_PATH.CaseDocumentDetailsPage}
            element={<CaseDocumentDetailsPage />}
          />
          <Route
            path={PAGE_PATH.CaseProfilePage}
            element={<ProfilePageCoop />}
          />
          <Route
            path={PAGE_PATH.CaseNotificationsPage}
            element={<NotificationsPageCoop />}
          />
          <Route
            path={PAGE_PATH.CaseSharingPage}
            element={<CaseSharingPage />}
          />
          <Route
            path={PAGE_PATH.CaseSharedWithMePage}
            element={<CaseSharedWithMePage />}
          />
          <Route
            path={PAGE_PATH.CaseSharedWithMeFileDetailsPage}
            element={<CaseSharedWithMeFileDetailsPage />}
          />
          <Route
            path={PAGE_PATH.CaseDocumentAddSignature}
            element={<AddSignaturePage />}
          />
          <Route
            path={PAGE_PATH.NotificationsPage}
            element={<NotificationsPage />}
          />
          <Route path={PAGE_PATH.CaseSearchPage} element={<CaseSearchPage />} />

          {/* Case manager pages */}
          <Route
            path={PAGE_PATH.CaseManagerHomePage}
            element={<CaseManagerHomePage />}
          />
          <Route
            path={PAGE_PATH.CaseManagerAnalyticsPage}
            element={<CaseManagerAnalyticsPage />}
          />
          <Route
            path={PAGE_PATH.CaseManagerDocumentRequestPage}
            element={<CaseManagerRequestPage />}
          />
          <Route
            path={PAGE_PATH.CaseManagerDocumentTaggerIframePage}
            element={<CaseManagerDocumentTaggerIframePage />}
          />
          <Route
            path={PAGE_PATH.CaseManagerDocumentSentPage}
            element={<CaseManagerDocumentSentPage />}
          />

          {/* This LogoutCaseManagerPage route is needed for clearing out keys from local storage for COOP case handler we might need a Logout link as well in the UI */}
          <Route
            path={PAGE_PATH.CaseManagerLogout}
            element={<LogoutCaseManagerPage />}
          />

          {/* Case Contact user pages */}
          <Route
            path={PAGE_PATH.CaseContactSharedWithMePage}
            element={<CaseSharedWithMePage />}
          />
          <Route
            path={PAGE_PATH.CaseContactFileDetailsPage}
            element={<CaseSharedWithMeFileDetailsPage />}
          />
          <Route path={PAGE_PATH.ProfilePage} element={<ProfilePageCoop />} />

          <Route path={PAGE_PATH.Logout} element={<LogoutPage />} />
        </Route>

        <Route
          path={PAGE_PATH.UnauthorisedPage}
          element={<CoopUnauthorisedPage />}
        />
        <Route
          path="*"
          element={
            <RequireAuth
              loginType={loginType}
              showLoginPage={showLoginPage}
              isLoading={authLoading}
            />
          }
        />
      </Routes>
    )
  }

  //fnz specific adviser client only page
  if (
    hasModules([UserModuleType.DOCUMENTS_LIBRARY_ADVISER_VIEW]) &&
    searchParams.context
  ) {
    return (
      <Routes>
        <Route
          path={PAGE_PATH.AccountDocumentLibraryPage}
          element={<AccountDocumentLibraryPage />}
        />
        <Route path={PAGE_PATH.ErrorPage} element={<ErrorPage />} />
        <Route path="*" element={<Redirect />} />
      </Routes>
    )
  }

  if (hasModules(CommunicationsModules) && !searchParams.context) {
    return (
      <Routes>
        {hasModules([UserModuleType.DOCUMENT_LIBRARY]) && (
          <Route
            path={PAGE_PATH.DocumentLibraryPage}
            element={<DocumentLibraryPage />}
          />
        )}

        {hasModules([UserModuleType.DOCUMENT_HUB]) && (
          <>
            <Route
              path={PAGE_PATH.DocumentHubPage}
              element={<DocumentHubPage />}
            />
            <Route path={PAGE_PATH.AnalyticsPage} element={<AnalyticsPage />} />
            <Route
              path={PAGE_PATH.CommunicationsSearchPage}
              element={<CommunicationsSearchPage />}
            />
          </>
        )}
        <Route path={PAGE_PATH.ErrorPage} element={<ErrorPage />} />
        <Route path="*" element={<Redirect />} />
      </Routes>
    )
  }

  return (
    <Routes>
      {/* TODO: This checks only cater for Admin users we need to fix in a separated ticket for advisers
       */}
      {hasModules([UserModuleType.ORGANISATIONS_ADMIN]) &&
        hasModules([UserModuleType.CLIENT_LIST]) && (
          <>
            <Route
              path={PAGE_PATH.Organisations}
              element={<OrganisationsPage />}
            />
            <Route
              path={PAGE_PATH.OrganisationClients}
              element={<ClientOrganisationsPage />}
            />
          </>
        )}
      {hasModules([UserModuleType.VAULT]) && (
        <>
          <Route path={PAGE_PATH.HomePage} element={<HomePage />} />
          <Route path={PAGE_PATH.FolderPage} element={<FolderPage />} />
        </>
      )}
      {hasModules([UserModuleType.SHARING]) && (
        <Route path={PAGE_PATH.SharingPage} element={<SharingPage />} />
      )}
      {hasModules([
        UserModuleType.SHARED_WITH_ME_LIST,
        UserModuleType.SHARED_WITH_ME_VIEW,
        UserModuleType.SHARED_WITH_ME_EDIT,
      ]) &&
        !hasModules([UserModuleType.CLIENT_LIST]) && (
          <Route
            path={PAGE_PATH.SharedWithMePage}
            element={<SharedWithMePage />}
          />
        )}
      {!hasModules([UserModuleType.ORGANISATIONS_ADMIN]) &&
        hasModules([UserModuleType.CLIENT_LIST]) && (
          <Route path={PAGE_PATH.ClientsPage} element={<ClientsPage />} />
        )}
      {hasModules([UserModuleType.SEARCH]) && (
        <Route
          path={PAGE_PATH.SearchResultPage}
          element={<SearchResultPage />}
        />
      )}
      {hasModules([UserModuleType.AUDIT_LOG]) && (
        <Route
          path={PAGE_PATH.RecentActivityPage}
          element={<RecentActivityPage />}
        />
      )}
      {hasModules([UserModuleType.NOTIFICATIONS]) && (
        <Route
          path={PAGE_PATH.NotificationsPage}
          element={<NotificationsPage />}
        />
      )}
      {hasModules([UserModuleType.CONNECTIVITY]) && (
        <Route
          path={PAGE_PATH.ConnectivityPage}
          element={<ConnectivityPage />}
        />
      )}
      <Route path={PAGE_PATH.PrimerPage} element={<PrimerPage />} />
      <Route path={PAGE_PATH.ProfilePage} element={<ProfilePage />} />
      {hasModules([UserModuleType.VAULT_DOCUMENT_UPLOAD_FOR_SIGNING]) && (
        <>
          <Route
            path={PAGE_PATH.DocusignRequestPage}
            element={<DocusignRequestPage />}
          />
          <Route
            path={PAGE_PATH.DocusignRequestSentPage}
            element={<DocusignRequestSentPage />}
          />
          <Route
            path={PAGE_PATH.DocusignRequestIframePage}
            element={<DocusignRequestIframePage />}
          />
          <Route
            path={PAGE_PATH.DocuSignAddSignature}
            element={<AddSignaturePage />}
          />
          <Route
            path={PAGE_PATH.DocusignRedirect}
            element={<DocusignRedirectPage />}
          />
        </>
      )}

      <Route path={PAGE_PATH.ThemePage} element={<ThemePage />} />
      <Route path={PAGE_PATH.ErrorPage} element={<ErrorPage />} />
      <Route path="*" element={<Redirect />} />
    </Routes>
  )
}

export const Routing: FC<{ children?: ReactNode }> = ({ children }) => {
  const { dispatch } = useApplicationContext()
  const { loginType, themeName } = usePlatformContext()
  const {
    userState: { currentUser },
    dispatch: dispatchUser,
  } = useUserContext()
  const { dispatch: dispatchThumbnail } = useThumbnailContext()
  const { dispatch: toastDispatch } = useToastContext()

  useEffect(() => {
    apiConnect(
      dispatch,
      dispatchUser,
      dispatchThumbnail,
      toastDispatch,
      loginType
    )
    if (loginType === loginTypes.PASSWORD) {
      dispatch(setSelectedTheme(themeName as TThemeName))
      dispatch(setAuthLoading(false))
    }
  }, [
    dispatch,
    dispatchUser,
    dispatchThumbnail,
    loginType,
    themeName,
    toastDispatch,
  ])

  useEffect(() => {
    loadUserData({
      currentUser,
      dispatch,
      toastDispatch,
    })
  }, [currentUser, dispatch, loginType, toastDispatch])

  useRefreshTokenOnTabFocus()

  return (
    <MainLayoutWrapper>
      <Suspense fallback={<LoadingFallBack />}>
        <AllRoutes />
      </Suspense>
      {children}
    </MainLayoutWrapper>
  )
}
